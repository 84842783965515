import _ from 'lodash'
import React, { FC, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import FavoriteCardButtons from './FavoriteCardButtons'

import {
  DatePicker,
  FormInputFoodAmount,
  FormInputSelect,
  Mode,
  ResponsiveCardBase,
  buildEatableDetails
} from 'Components'
import { deleteButton, editButton } from 'Components/IconButton/iconButtons'
import {
  SelectItemKeyExtractor,
  SelectItemRenderer,
  SelectItemType,
  defaultKeyExtractor
} from 'Components/common/SelectPickerProps'
import { Amount, FavoritesRecipe, MealCategory, PromiseAction, Recipe, allMealCategories } from 'Models'
import { removeFavoritesAction, updateFavoritesAction } from 'ReduxStore/favorites/favorites'
import { setJournalDateAction } from 'ReduxStore/journalDay'
import { postOrPatchJournalEatableRecordAction } from 'ReduxStore/journalEatableRecords'
import { Formatter, Utils } from 'Utils'

type Props = {
  favorite: FavoritesRecipe
  recipe: Recipe
  onClick: () => void
}

const FavoriteRecipeOverviewCard: FC<Props> = ({ favorite, onClick }: Props) => {
  const dispatch = useDispatch()

  const history = useHistory()

  const [toggleChildrenIsOn, setToggleChildrenIsOn] = useState(false)

  const [mode, setMode] = useState<Mode>('normal')

  const [quantifiedEatable, setQuantifiedEatable] = useState({
    recipeId: favorite.recipe.id,
    recipe: favorite.recipe,
    quantity: favorite.quantity,
    unit: favorite.unit
  })

  const availableUnits = Utils.availableUnits(quantifiedEatable)

  const [amount, setAmount] = useState<Amount>({
    quantity: quantifiedEatable.quantity,
    unit: quantifiedEatable.unit
  })

  const [date, setDate] = useState<Date>(new Date())

  const [category, setCategory] = useState<MealCategory>(() => Utils.currentMealCategory())

  const handleCategoryChange = (category: MealCategory): void => {
    setCategory(category as MealCategory)
  }

  const handleAmountChanged = useCallback(
    _.debounce(
      ({ unit, quantity }: Amount) =>
        dispatch(
          updateFavoritesAction(favorite.id, {
            recipeId: quantifiedEatable.recipeId,
            quantity,
            unit
          })
        ),
      1000
    ),
    []
  )

  const handleOnEdit = (): void => {
    if (mode !== 'normal') {
      setMode('normal')
      setToggleChildrenIsOn(true)
    } else {
      setToggleChildrenIsOn(!toggleChildrenIsOn)
    }
  }

  const handleDeleteFavorite = (): PromiseAction<void> => dispatch(removeFavoritesAction(favorite))

  const handleFavoriteAmountChange = useCallback((amount: Amount): void => {
    const modifiedEatable = { recipeId: quantifiedEatable.recipeId, recipe: quantifiedEatable.recipe, ...amount }
    const { unit = 'GRAM', quantity = 0 } = modifiedEatable

    if (quantity > 0 && (quantifiedEatable.unit !== unit || quantifiedEatable.quantity !== quantity)) {
      setQuantifiedEatable(modifiedEatable)
      handleAmountChanged({ unit, quantity })
    }
  }, [])

  const details = buildEatableDetails(
    { recipeId: favorite.recipe.id, recipe: favorite.recipe },
    { quantity: favorite.quantity, unit: favorite.unit },
    'normal'
  )

  const handleSave = async (): Promise<void> => {
    setMode('normal')
    const result = await dispatch(
      postOrPatchJournalEatableRecordAction(
        { recipe: favorite.recipe, quantity: amount.quantity, unit: amount.unit },
        category,
        date,
        'favorites-overview'
      )
    )

    if ('error' in result) {
      return
    }

    dispatch(setJournalDateAction(date))
    history.push('/tagebuch')
  }

  return (
    <ResponsiveCardBase
      key={favorite.id}
      onClick={onClick}
      title={favorite.recipe.name}
      isFavorite={true}
      toggleChildrenIsOn={toggleChildrenIsOn}
      details={details}
      className="is-eatable"
      imageUrl={favorite.recipe.imageUrl}
      cardButtons={[
        <FavoriteCardButtons
          mode={mode}
          handleSave={handleSave}
          setMode={setMode}
          setToggleChildrenIsOn={setToggleChildrenIsOn}
          key={favorite.recipe.name}
        />
      ]}
      iconButtons={[editButton(handleOnEdit), deleteButton(handleDeleteFavorite)]}
    >
      <div className="standardcard-options">
        {mode === 'adding' && (
          <>
            <div>
              <FormInputFoodAmount
                label=" "
                amount={amount}
                availableUnits={availableUnits}
                onAmountChange={setAmount}
                onChangeDelay={0}
              />
            </div>
            <div>
              <DatePicker label=" " value={date} onChange={setDate} />
            </div>

            <div>
              <FormInputSelect
                label=" "
                name="mealCategory"
                items={allMealCategories}
                selectedKey={category ? defaultKeyExtractor(category) : undefined}
                labelExtractor={Formatter.formatMealCategory as SelectItemRenderer<SelectItemType, string>}
                keyExtractor={defaultKeyExtractor as SelectItemKeyExtractor<SelectItemType>}
                onChange={handleCategoryChange}
              />
            </div>
          </>
        )}

        {mode === 'normal' && (
          <div>
            <FormInputFoodAmount
              label=" "
              amount={amount}
              onAmountChange={handleFavoriteAmountChange}
              availableUnits={availableUnits}
            />
          </div>
        )}
      </div>
    </ResponsiveCardBase>
  )
}

export default FavoriteRecipeOverviewCard
