import React, { FC } from 'react'

import { Icon } from '../Icon'

import { IconName } from 'Components/Icon/Icon'

export interface IconButtonProps {
  name: IconName
  disabled?: boolean
  color?: string
  label?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  linkTitle?: string
  hiddenInPrint?: boolean
}

export type CardIconButton = Required<Omit<IconButtonProps, 'disabled' | 'linkTitle' | 'hiddenInPrint'>>

export const IconButton: FC<IconButtonProps> = ({
  name,
  disabled,
  color,
  label,
  onClick,
  linkTitle,
  hiddenInPrint
}: IconButtonProps) => {
  return (
    <a
      className={`d-flex iconbutton button-${name} ${disabled ? 'is-disabled' : ''}`}
      onClick={onClick}
      title={linkTitle}
    >
      <Icon name={name} color={color} hiddenInPrint={hiddenInPrint} />
      {label && <span className="ml-1">{label}</span>}
    </a>
  )
}
