import React, { useCallback, useMemo } from 'react'

import { FormInputFoodAmount } from '../FormInputFoodAmount'
import { FormInputSelect } from '../FormInputSelect'
import { ResponsiveCardBase, ResponsiveCardBaseProps } from '../ResponsiveCardBase/ResponsiveCardBase'

import { Mode } from 'Components/JournalCardSearchBase'
import { Amount, FavoritesMapping, FavoritesMotionLevel, Motion, MotionLevelObject, UnitId } from 'Models'
import { favoriteSelector } from 'ReduxStore/favorites/favorites'
import { useAppSelector } from 'ReduxStore/hooks'
import { Calculator, Formatter, Utils } from 'Utils'

export type JournalCardMotionProps = Pick<
  ResponsiveCardBaseProps,
  'onClick' | 'iconButtons' | 'cardButtons' | 'toggleChildrenIsOn' | 'isInFitnessPlan' | 'isAccepted'
> & {
  motion: Motion
  weight: number
  duration: number
  motionLevelId: string
  onDurationChange?: (duration: number) => void
  onMotionLevelIdChange?: (motionLevelId: string) => void
  mode?: Mode
  additionalInputs?: JSX.Element
}

const availableUnits: UnitId[] = ['MINUTE']

export const JournalCardMotion: React.FC<JournalCardMotionProps> = ({
  motion,
  weight,
  duration,
  motionLevelId,
  onDurationChange,
  onMotionLevelIdChange,
  mode,
  additionalInputs,
  ...responsiveCardBaseProps
}: JournalCardMotionProps) => {
  const levels = motion.levels

  const { isAccepted, isInFitnessPlan } = responsiveCardBaseProps

  const filterLevel = useMemo(() => levels.find((level) => level.id === motionLevelId), [motionLevelId, levels])
  const category = filterLevel || levels[0]
  const motionRecordLevelIds = motion.levels.map((level) => level.id)

  const favoriteMotionLevel = useAppSelector(
    favoriteSelector<FavoritesMotionLevel>(FavoritesMapping.MOTIONS, motionRecordLevelIds)
  )
  const energy = useMemo(
    () => Formatter.formatEnergy(Calculator.calculateMotionEnergy(category.metFactor, weight, duration)),
    [duration, weight, category]
  )

  const handleAmountChange = useCallback(
    (amount: Amount) => {
      onDurationChange && onDurationChange(Utils.durationFromAmount(amount))
    },
    [onDurationChange]
  )

  const handleCategoryChange = useCallback(
    (category) => {
      onMotionLevelIdChange && onMotionLevelIdChange(category.id)
    },
    [onMotionLevelIdChange]
  )

  const isRecord = !(isInFitnessPlan && !isAccepted)

  const showEditFavoriteInputs: boolean = !mode || mode === 'normal'

  return (
    <ResponsiveCardBase
      title={motion.name}
      details={energy + ' / ' + Formatter.formatDuration(duration)}
      className={`is-motion ${isInFitnessPlan && !isAccepted && 'is-fitness-plan'} ${
        isAccepted && 'is-fitness-plan-motion-record'
      }`}
      isFavorite={!!favoriteMotionLevel && isRecord}
      imageUrl={motion.imageUrl}
      {...responsiveCardBaseProps}
    >
      {showEditFavoriteInputs && (
        <div className="standardcard-options">
          <div>
            <FormInputFoodAmount
              label=" "
              amount={{ quantity: duration / 60, unit: 'MINUTE' }}
              availableUnits={availableUnits}
              onAmountChange={handleAmountChange}
            />
          </div>
          {levels && levels.length > 1 && (
            <div>
              <FormInputSelect
                label=" "
                items={levels}
                selectedKey={category.id}
                onChange={handleCategoryChange}
                keyExtractor={(category) => (category as MotionLevelObject).id}
                labelExtractor={(level) => (level as MotionLevelObject).name}
              />
            </div>
          )}
        </div>
      )}
      {mode === 'adding' && additionalInputs}
    </ResponsiveCardBase>
  )
}
