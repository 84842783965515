import React, { FC, useMemo, useState } from 'react'

import { FormInputNumber } from '../FormInputNumber'
import { Icon, IconName } from '../Icon'
import { Table } from '../Table'
import { TableNutrition } from '../TableNutrition'
import { DetailCardChart } from '../common/DetailCardChart'

import { AddRecipeToJournal } from './components/AddRecipeToJournal'

import RichTextPanel from 'Components/RichTextPanel/RichTextPanel'
import { BaseIngredient, IngredientReadData, Nutritions, Recipe } from 'Models'
import { Calculator, Formatter } from 'Utils'
import { usePreviousState } from 'Utils/helpers/usePreviousState'

interface Props {
  recipe: Recipe
  handlePrint: () => void
}

const DetailCardRecipeContent: FC<Props> = ({ recipe, handlePrint }: Props) => {
  const [requestedServingsCount, setRequestedServingsCount] = useState<number | undefined>(
    recipe.defaultServingsCount || 1
  )

  const previousServingsCount = usePreviousState(requestedServingsCount)
  const servingsCount = requestedServingsCount || previousServingsCount

  const nutritionsPer100g = useMemo(() => {
    return Calculator.multiplyNutritions(recipe.nutritionsPerPortion as Nutritions, 100 / (recipe.portionAmount || 1))
  }, [recipe.nutritionsPerPortion, recipe.portionAmount])

  const formatIngredientAmount = (ingredient: BaseIngredient): string => {
    const quantity = Calculator.calculateRecipeIngredientQty(recipe, ingredient, servingsCount)

    return Formatter.formatFoodAmount(quantity, ingredient.unit)
  }

  const isAddingToJournalPossible =
    window.location.pathname.includes('rezept-inspiration') || window.location.pathname.includes('favoriten')

  return (
    <>
      <div className="p-5 row justify-content-between">
        <div>
          <h2>{recipe.name}</h2>
        </div>
        <div onClick={() => handlePrint()} className="cursor">
          <Icon name={IconName.print} hiddenInPrint large />
        </div>
      </div>
      <div className="px-5 py-2 bg-gray-4">
        <div className="detailcard-calculator">
          <div className="row justify-content-between">
            <div className="col-auto d-flex align-items-center">
              <Icon name={IconName.utensils} />
              <span className="d-none d-lg-inline">Zutaten für</span>
              <FormInputNumber
                value={requestedServingsCount}
                onChange={setRequestedServingsCount}
                onChangeDelay={1000}
                maxFractions={1}
                step={0.5}
                hiddenInPrint
              />
              <span className="hiddenOnScreen mr-1">{requestedServingsCount}</span>
              Portionen
            </div>
            <div className="col-auto d-flex align-items-center">
              <Icon name={IconName.clock} />
              {recipe.preparationTime && (
                <>
                  {Formatter.formatDurationInMinutes(recipe.preparationTime)}
                  <span className="d-none d-lg-inline">&nbsp;Zubereitungszeit</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="p-5">
        <div className="row">
          <div className="col-12">
            <Table
              rows={
                recipe.ingredients
                  ? recipe.ingredients.map((ingredient: IngredientReadData) => [
                      formatIngredientAmount(ingredient),
                      ingredient.food.name
                    ])
                  : []
              }
              theme="recipe"
            />
          </div>
        </div>
      </div>
      <div className="p-5 hiddenInPrint">
        <div className="row">
          <div className="col-12 col-lg-6">
            <TableNutrition nutritionsPer100g={nutritionsPer100g} nutritionsPerPortion={recipe.nutritionsPerPortion} />
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <DetailCardChart nutritionsPerWeight={recipe.nutritions || {}} />
          </div>
        </div>
      </div>
      <div className="p-5">
        <div className="row">
          {recipe.hint && (
            <div className="col-12">
              <h2>Hinweis</h2>
              <p>{recipe.hint}</p>
            </div>
          )}
          <div className="col-12">
            <h2>Beschreibung</h2>
          </div>
          <div className="col-12">
            <RichTextPanel text={recipe.description} />
          </div>
          {recipe.source && (
            <div className="col-12">
              <p>Quelle: {recipe.source.name}</p>
            </div>
          )}
          {isAddingToJournalPossible && (
            <div className="hiddenInPrint mt-4 col-12">
              <AddRecipeToJournal recipe={recipe} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DetailCardRecipeContent
