const RecipeCategoryTitles = {
  SIDE_DISH: 'Beilagen',
  DESSERT: 'Dessert',
  FINGERFOOD: 'Fingerfood',
  PASTRY: 'Gebäck',
  DRINKS: 'Getränke',
  MAIN_COURSE: 'Hauptspeisen',
  SOUP_STEW: 'Suppen & Eintöpfe',
  SALAD: 'Salate',
  STARTERS: 'Vorspeisen / Apéro',
  VEGETARIAN: 'Vegetarisch',
  VEGAN: 'Vegan',
  PASTA_DISH: 'Teigwarengerichte',
  BREAKFAST: 'Frühstück',
  SNACK: 'Snack',
  FISH_SEAFOOD: 'Fisch & Meeresfrüchte',
  MEAT: 'Fleisch',
  SAUCE: 'Sauce',
  BARBECUE: 'Grill',
  BREAD: 'Brot',
  SIMPLE: 'einfach',
  CHEAP: 'günstig',
  FESTIVE: 'festlich',
  FAST: 'schnell',
  LACTOSE_LOW: 'Lactose arm',
  GLUTEN_FREE: 'glutenfrei',
  PULTRY: 'Geflügel',
  POTATO: 'Kartoffeln',
  CHRISTMAS: 'Weihnachtliche Rezepte',
  BRUNCH: 'Brunch',
  CASSEROLE: 'Aus dem Ofen',
  SWISS_CLASSIC: 'Schweizer Klassiker',
  ITALIAN: 'Italienische Küche',
  RISOTTO: 'Risotto',
  ICE_CREAM: 'Glace',
  UNDER_FIVE_INGREDIENTS: 'Weniger als 5 Zutaten',
  VEGETABLE: 'Gemüse',
  PIZZA: 'Pizza',
  BURGER: 'Burger',
  QUICHE_PIE: 'Quiche & Wähe',
  FAMILY: 'Familenrezepte',
  OFFICE: 'Rezepte fürs Büro',
  FONDUE: 'Fondue',
  RACLETTE: 'Raclette',
  TOFU: 'Tofu',
  SMOOTHIE: 'Smoothies',
  NO_ADDED_SUGAR: 'Kein Zusatz von Zucker',
  LUNCH_UNDER_300_KCAL: 'Leichtes Mittagessen unter 300 kcal',
  REGIONAL: 'Rezepte mit regionalen Zutaten',
  ASIAN: 'Asiatische Küche',
  FRUIT: 'Früchte',
  SEASONAL_WINTER: 'Saisonale Rezepte - Winter',
  SEASONAL_SPRING: 'Saisonale Rezepte - Frühling',
  SEASONAL_SUMMER: 'Saisonale Rezepte - Sommer',
  SEASONAL_FALL: 'Saisonale Rezepte - Herbst',
  SANDWICH: 'Sandwich',
  LEGUMES: 'Linsen, Kichererbsen und co.',
  MUSHROOMS: 'Pilze',
  EGGS: 'Eier',
  INTERNATIONAL: 'Internationale Küche'
}

export default RecipeCategoryTitles
