import React, { useCallback, useMemo, useState } from 'react'

import { JournalCardSearchBase, Mode, dateFormatGeneric, dateFormatToday } from '../JournalCardSearchBase'

import {
  Amount,
  FavoritesFood,
  FavoritesMapping,
  FavoritesRecipe,
  MealCategory,
  QuantifiedEatable,
  allMealCategories
} from 'Models'
import { favoriteSelector } from 'ReduxStore/favorites/favorites'
import { useAppSelector } from 'ReduxStore/hooks'
import { Calculator, Formatter, Utils } from 'Utils'

interface Props {
  quantifiedEatable: QuantifiedEatable
  onSave?: (eatable: QuantifiedEatable, date: Date, category: MealCategory) => void
  renderTitle?: () => JSX.Element
  onDelete?: () => void
  initialDate: Date
}

export const buildEatableDetails = (
  item: QuantifiedEatable,
  amount: Amount,
  mode: Mode,
  date?: Date,
  mealCategory?: MealCategory
): string => {
  const eatable: QuantifiedEatable = { ...item, ...amount }
  const weight = Calculator.calculateEatableWeight(eatable)
  const energy = Calculator.calculateEnergy(eatable)
  const divider = mode === 'submitted' ? ' | ' : ' / '

  let formattedEatableAmount = Formatter.formatEatableAmount(amount)
  if (weight && amount.unit.indexOf('GRAM') === -1 && amount.unit !== 'MILLILITER' && mode !== 'submitted') {
    formattedEatableAmount += ` (${Formatter.formatEatableAmount(weight)})`
  }

  const result = [Formatter.formatEnergy(energy), formattedEatableAmount]
  if (mode === 'submitted') {
    date && result.push(Formatter.formatDate(date, dateFormatToday, dateFormatGeneric))
    mealCategory && result.push(Formatter.formatMealCategory(mealCategory))
  }
  return result.join(divider)
}

export const JournalCardSearchEatable: React.FC<Props> = ({ quantifiedEatable, onSave, ...props }: Props) => {
  const eatable = quantifiedEatable.food ?? quantifiedEatable.recipe
  const title = eatable?.name
  const source = eatable?.source?.name
  const imageUrl = eatable?.imageUrl
  const availableUnits = Utils.availableUnits(quantifiedEatable)
  const [mealCategory, setMealCategory] = useState<MealCategory>(() => Utils.currentMealCategory())
  const favorite = useAppSelector(
    favoriteSelector<FavoritesRecipe | FavoritesFood>(
      quantifiedEatable.food ? FavoritesMapping.FOODS : FavoritesMapping.RECIPES,
      quantifiedEatable.food ? quantifiedEatable.food.id : quantifiedEatable.recipe?.id
    )
  )

  const initialEatableAmount: Amount = useMemo(
    () =>
      favorite ? { quantity: favorite.quantity!, unit: favorite.unit! } : Utils.defaultEatableAmount(quantifiedEatable),
    []
  )

  const [eatableAmount, setEatableAmount] = useState(initialEatableAmount)

  const handleSave = useCallback(
    (eatable, amount, date, mealCategory) => {
      onSave &&
        onSave(
          {
            ...eatable,
            ...amount
          },
          date,
          mealCategory
        )
    },
    [onSave]
  )
  return (
    <JournalCardSearchBase
      {...props}
      source={source}
      item={quantifiedEatable}
      title={title}
      className="is-eatable"
      imageUrl={imageUrl}
      availableUnits={availableUnits}
      isFavorite={!!favorite}
      buildDetails={buildEatableDetails}
      categoryLabelRenderer={Formatter.formatMealCategory}
      amount={eatableAmount}
      onAmountChange={setEatableAmount}
      categories={allMealCategories}
      category={mealCategory}
      onCategoryChange={setMealCategory}
      onSave={handleSave}
    />
  )
}
