import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import FavoriteCardButtons from './FavoriteCardButtons'

import { DatePicker, FormInputFoodAmount, FormInputSelect, JournalCardMotion, Mode } from 'Components'
import { deleteButton, editButton } from 'Components/IconButton/iconButtons'
import { today } from 'Config'
import { Amount, FavoritesMotionLevel, PromiseAction } from 'Models'
import { removeFavoritesAction, updateFavoriteMotionAction } from 'ReduxStore/favorites/favorites'
import { setJournalDateAction } from 'ReduxStore/journalDay'
import { addJournalMotionRecordAction } from 'ReduxStore/journalMotionRecords'

type Props = {
  favorite: FavoritesMotionLevel
  weight: number
}

const FavoriteMotionCardContainer: React.FC<Props> = ({ favorite, weight }) => {
  const dispatch = useDispatch()

  const history = useHistory()

  const [toggleChildrenIsOn, setToggleChildrenIsOn] = useState(false)

  const [duration, setDuration] = useState(favorite.duration)

  const [motionLevelId, setMotionLevelId] = useState(favorite.motionLevel.id)

  const [mode, setMode] = useState<Mode>('normal')

  const [date, setDate] = useState<Date>(today)

  const [amount, setAmount] = useState<Amount>({ quantity: favorite.duration / 60, unit: 'MINUTE' } as Amount)

  const [category, setCategory] = useState(favorite.motionLevel)

  const additionalInputs = (
    <div className="standardcard-options">
      <div>
        <FormInputFoodAmount
          label=" "
          amount={amount}
          availableUnits={['MINUTE']}
          onAmountChange={setAmount}
          onChangeDelay={0}
        />
      </div>
      <div>
        <DatePicker label=" " value={date} onChange={setDate} />
      </div>
      <div>
        <FormInputSelect
          label=" "
          name="motionLevel"
          items={favorite.motionLevel.motion.levels.map((level) => ({
            ...level,
            motion: favorite.motionLevel.motion
          }))}
          selectedKey={category ? category.id : undefined}
          labelExtractor={(category) => category.name}
          keyExtractor={(category) => category.id}
          onChange={setCategory}
        />
      </div>
    </div>
  )

  const handleAmountChanged = useCallback(
    _.debounce(
      (durationInSeconds: number, newMotionLevelId: string) =>
        dispatch(
          updateFavoriteMotionAction(
            favorite.id,
            {
              duration: durationInSeconds,
              motionLevelId: favorite.motionLevel.id,
              newMotionLevelId
            },
            favorite
          )
        ),
      1000
    ),
    [favorite.id]
  )

  const onDurationChange = (durationInSeconds: number): void => {
    setDuration(durationInSeconds)
    handleAmountChanged(durationInSeconds, motionLevelId)
  }

  const onMotionLevelIdChange = (motionLevelId: string): void => {
    setMotionLevelId(motionLevelId)
    handleAmountChanged(duration, motionLevelId)
  }

  const handleOnEdit = (): void => {
    if (mode !== 'normal') {
      setMode('normal')
      setToggleChildrenIsOn(true)
    } else {
      setToggleChildrenIsOn(!toggleChildrenIsOn)
    }
  }

  const handleDeleteFavorite = (): PromiseAction<void> => dispatch(removeFavoritesAction(favorite))

  const handleSave = async (): Promise<void> => {
    const result = await dispatch(
      addJournalMotionRecordAction(favorite.motionLevel.motion, duration, favorite.motionLevel.id, date)
    )

    if ('error' in result) {
      return
    }

    dispatch(setJournalDateAction(date))

    history.push('/tagebuch')
  }

  return (
    <JournalCardMotion
      iconButtons={[editButton(handleOnEdit), deleteButton(handleDeleteFavorite)]}
      cardButtons={[
        <FavoriteCardButtons
          mode={mode}
          handleSave={handleSave}
          setMode={setMode}
          setToggleChildrenIsOn={setToggleChildrenIsOn}
          key={favorite.motionLevel.name}
        />
      ]}
      toggleChildrenIsOn={toggleChildrenIsOn}
      motion={favorite.motionLevel.motion}
      weight={weight}
      duration={duration}
      onDurationChange={onDurationChange}
      motionLevelId={motionLevelId}
      onMotionLevelIdChange={onMotionLevelIdChange}
      mode={mode}
      additionalInputs={additionalInputs}
    />
  )
}

export default FavoriteMotionCardContainer
