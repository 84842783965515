import _ from 'lodash'
import React, { ChangeEvent, FC, RefObject, memo, useCallback, useEffect, useMemo, useState } from 'react'

import { StepperButton } from './StepperButton'

import { Calculator } from 'Utils'
import { usePreviousState } from 'Utils/helpers/usePreviousState'

/**
 * If step is defined, maxFractions must be equal to the number of fractions of
 * step, e.g. if step=0.5 => maxFractions must be equal 1
 * Otherwise step alignment might change the value with an invalid number of
 * fractions which makes the input unusable, e.g. step=0.3, maxFractions=0, user
 * enters 2 => step alignment changes value to 2.1 which has an invalid number
 * of fractions
 */

type ThemeType = 'large'

export type FormInputNumberProps = {
  name?: string
  label?: string
  value?: number
  defaultValue?: string
  lowerBound?: number
  maxFractions?: number
  decimalSeparator?: string
  disabled?: boolean
  step?: number
  onBlur?: () => void
  onChange?: (value?: number) => void
  onChangeDelay?: number
  unitLabel?: string
  errorText?: string
  theme?: ThemeType
  inputRef?: RefObject<HTMLInputElement>
  hiddenInPrint?: boolean
}

const FormInputNumber: FC<FormInputNumberProps> = ({
  name,
  label,
  value,
  defaultValue,
  lowerBound = 0,
  maxFractions = 0,
  decimalSeparator = '.',
  disabled = false,
  step = 0,
  unitLabel,
  errorText,
  onBlur,
  onChange,
  onChangeDelay = 0,
  theme,
  inputRef,
  hiddenInPrint
}: FormInputNumberProps) => {
  const withStepperButtons = useMemo(() => step > 0, [step])
  const isLarge = useMemo(() => theme === 'large', [theme])
  const valueToText = useCallback(
    (val?: number) =>
      val !== undefined && val !== null ? val.toFixed(maxFractions).replace('.', decimalSeparator) : '',
    [decimalSeparator]
  )

  const textToValue = useCallback((val?: string) => (val ? Number(val.replace(decimalSeparator, '.')) : undefined), [
    decimalSeparator
  ])

  const [text, setText] = useState(() => valueToText(value))
  const prevText = usePreviousState(text)

  useEffect(() => {
    if (value === undefined || value === Number(textToValue(text))) {
      return
    }
    setText(valueToText(value))
  }, [value])

  const onChangeCallback = useCallback(() => {
    if (prevText !== undefined && text !== prevText) {
      if (text.length === 0) {
        return
      }
      onChange && onChange(textToValue(text))
    }
  }, [text, prevText, onChange])

  const handleDelayedInputNumber = useCallback(
    _.debounce(() => {
      onChangeCallback()
    }, onChangeDelay),
    [onChangeCallback, onChangeDelay]
  )

  useEffect(() => {
    if (onChangeDelay) {
      handleDelayedInputNumber()
      return handleDelayedInputNumber.cancel
    } else {
      onChangeCallback()
    }
  }, [onChangeCallback, handleDelayedInputNumber])

  const handleInputChange = ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>): void => {
    const regex = maxFractions ? new RegExp(`^(\\d+(${_.escapeRegExp(decimalSeparator)}\\d*)?)?$`) : /^$|([1-9])\d*$/
    if (!regex.test(newValue)) {
      return
    }

    const parts = newValue.split(decimalSeparator)

    if (parts.length === 2 && parts[1].length > maxFractions) {
      return
    }

    setText(newValue)
  }

  const handleStepUp = useCallback(() => {
    const oldValue = Calculator.roundNumberStep(textToValue(text) || 0, step)
    const newValue = oldValue + step
    setText(valueToText(newValue))
  }, [text, step])

  const handleStepDown = useCallback(() => {
    const oldValue = Calculator.roundNumberStep(textToValue(text) || 0, step)
    const newValue = oldValue - step
    if (lowerBound !== undefined && newValue < lowerBound) return
    setText(valueToText(newValue))
  }, [text, step, lowerBound])

  return (
    <div
      className={`input ${withStepperButtons ? 'has-stepper-buttons' : ''} ${isLarge ? 'is-large' : ''} ${
        hiddenInPrint ? 'hiddenInPrint' : ''
      }`}
    >
      <input
        ref={inputRef}
        type="text"
        placeholder=" "
        name={name}
        value={text}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={handleInputChange}
        onBlur={onBlur}
      />
      {withStepperButtons && (
        <>
          <StepperButton disabled={disabled} changeCurrentValue={handleStepDown} />
          <StepperButton disabled={disabled} changeCurrentValue={handleStepUp} />
        </>
      )}
      {label && !withStepperButtons && <label>{label}</label>}
      {unitLabel && <span className="per-unit">{unitLabel}</span>}
      {errorText && <div className="input-error">{errorText}</div>}
    </div>
  )
}

export default memo(FormInputNumber)
