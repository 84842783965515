import dayjs from 'dayjs'
import { AnyAction, combineReducers } from 'redux'
import { createSelector } from 'reselect'

import {
  ADD_JOURNAL_MOTION_RECORD,
  ADD_JOURNAL_MOTION_RECORD_FULFILLED,
  ADD_JOURNAL_MOTION_RECORD_PENDING,
  ADD_JOURNAL_MOTION_RECORD_REJECTED,
  FETCH_JOURNAL_MOTION_RECORDS,
  FETCH_JOURNAL_MOTION_RECORDS_FULFILLED,
  REMOVE_JOURNAL_MOTION_RECORD,
  REMOVE_JOURNAL_MOTION_RECORD_PENDING,
  REMOVE_JOURNAL_MOTION_RECORD_REJECTED,
  UPDATE_JOURNAL_MOTION_RECORD,
  UPDATE_JOURNAL_MOTION_RECORD_FULFILLED
} from './actionTypes'
import { createPendingCounterReducer } from './common'
import { currentJournalDateSelector } from './journalDay'

import { today } from 'Config'
import {
  JournalMotionRecord,
  JournalMotionRecordsReduxState,
  Motion,
  PromiseAction,
  ReduxStoreState,
  ThunkAction
} from 'Models'
import { coreBackendService } from 'Services'
import { Utils } from 'Utils'

// Helper functions
const findJournalMotionRecord = (
  journalMotionRecords: JournalMotionRecord[],
  date: Date,
  motionId: string,
  motionLevelId: string
): JournalMotionRecord | undefined => {
  return journalMotionRecords
    ? journalMotionRecords.find(
        (journalMotionRecord) =>
          dayjs(journalMotionRecord.datetime).isSame(date, 'day') &&
          journalMotionRecord.motion.id === motionId &&
          journalMotionRecord.motionLevelId === motionLevelId
      )
    : undefined
}

// Reducer
const data = (state: JournalMotionRecord[] = [], { type, payload, meta }: AnyAction): JournalMotionRecord[] => {
  switch (type) {
    case FETCH_JOURNAL_MOTION_RECORDS_FULFILLED:
      return Utils.unique<JournalMotionRecord>([...state, ...payload])
    case ADD_JOURNAL_MOTION_RECORD_PENDING:
    case REMOVE_JOURNAL_MOTION_RECORD_REJECTED:
      return [...state, meta.data]
    case ADD_JOURNAL_MOTION_RECORD_FULFILLED: {
      const newState = [...state, payload]
      return newState.filter((record: JournalMotionRecord) => record.id !== meta.data.id)
    }
    case ADD_JOURNAL_MOTION_RECORD_REJECTED:
    case REMOVE_JOURNAL_MOTION_RECORD_PENDING:
      return state.filter((record: JournalMotionRecord) => record.id !== meta.data.id)
    case UPDATE_JOURNAL_MOTION_RECORD_FULFILLED:
      return state.map((record: JournalMotionRecord) =>
        record.id === meta.data.id
          ? {
              ...record,
              duration: meta.data.duration,
              datetime: meta.data.datetime,
              motionLevelId: meta.data.motionLevelId
            }
          : { ...record }
      )

    default:
      return state
  }
}

// Actions
export const fetchJournalMotionRecordsAction = (date: Date): PromiseAction<JournalMotionRecord[]> => ({
  type: FETCH_JOURNAL_MOTION_RECORDS,
  payload: coreBackendService.fetchJournalMotionRecords({ date })
})

export const updateJournalMotionRecordAction = (
  id: string,
  duration: number,
  motionLevelId: string,
  datetime?: Date
): PromiseAction<void> => ({
  type: UPDATE_JOURNAL_MOTION_RECORD,
  payload: coreBackendService.updateJournalMotionRecord(id, duration, motionLevelId, datetime),
  meta: {
    triggerDefaultSuccessToast: true,
    data: { id, duration, motionLevelId, datetime }
  }
})

export const removeJournalMotionRecordAction = (id: string, record?: JournalMotionRecord): PromiseAction<void> => ({
  type: REMOVE_JOURNAL_MOTION_RECORD,
  payload: coreBackendService.deleteJournalMotionRecord(id),
  meta: {
    triggerDefaultSuccessToast: true,
    data: record
  }
})

export const copyJournalMotionRecordAction = (
  motion: Motion,
  duration: number,
  motionlevelId: string,
  date: Date = today
): ThunkAction => {
  return async (dispatch) => {
    return dispatch({
      type: ADD_JOURNAL_MOTION_RECORD,
      payload: coreBackendService.addJournalMotionRecord(duration, motionlevelId, date),
      meta: {
        triggerDefaultSuccessToast: true,
        data: { id: `tmp-${Date.now()}`, motion, datetime: date }
      }
    })
  }
}

export const addJournalMotionRecordAction = (
  motion: Motion,
  duration: number,
  motionlevelId: string,
  date: Date = today,
  journalRecordId?: string
): ThunkAction => {
  return async (dispatch, getState) => {
    const {
      journalMotionRecords: { data }
    } = getState()

    let journalMotionRecord = findJournalMotionRecord(data, date, motion.id, motionlevelId)

    if (!journalMotionRecord) {
      const { value } = await dispatch(fetchJournalMotionRecordsAction(date))

      journalMotionRecord = findJournalMotionRecord(value, date, motion.id, motionlevelId)
    }

    if (!journalMotionRecord) {
      return dispatch({
        type: ADD_JOURNAL_MOTION_RECORD,
        payload: coreBackendService.addJournalMotionRecord(
          duration,
          motionlevelId,
          date,
          journalRecordId && `fitness-plan:${journalRecordId}`
        ),
        meta: {
          triggerDefaultSuccessToast: true,
          data: { id: `tmp-${Date.now()}`, motion, datetime: date }
        }
      })
    } else {
      return dispatch(
        updateJournalMotionRecordAction(
          journalMotionRecord.id,
          duration + journalMotionRecord.duration,
          journalMotionRecord.motionLevelId,
          journalMotionRecord.datetime
        )
      )
    }
  }
}

// Selectors
export const journalMotionRecordsSelector = ({
  journalMotionRecords
}: ReduxStoreState): JournalMotionRecordsReduxState => journalMotionRecords

export const journalMotionRecordsDataSelector = ({ journalMotionRecords }: ReduxStoreState): JournalMotionRecord[] =>
  journalMotionRecords.data

export const dayJournalMotionRecordsSelector = createSelector(
  [currentJournalDateSelector, journalMotionRecordsDataSelector],
  (currentJournalDate, journalMotionRecords) =>
    journalMotionRecords
      .filter((journalRecord) =>
        journalRecord.datetime ? dayjs(currentJournalDate).isSame(journalRecord.datetime, 'day') : false
      )
      .sort(
        (recordA, recordB) => new Date(recordA.createdAt ?? 0).getTime() - new Date(recordB.createdAt ?? 0).getTime()
      )
)

const asyncActionTypes = [
  FETCH_JOURNAL_MOTION_RECORDS,
  ADD_JOURNAL_MOTION_RECORD,
  UPDATE_JOURNAL_MOTION_RECORD,
  REMOVE_JOURNAL_MOTION_RECORD
]

export default combineReducers<JournalMotionRecordsReduxState>({
  data,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes)
})
