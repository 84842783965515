import React, { FC } from 'react'

import { Button, Mode } from 'Components'

type Props = {
  mode: Mode
  handleSave: () => Promise<void>
  setMode: React.Dispatch<React.SetStateAction<Mode>>
  setToggleChildrenIsOn: React.Dispatch<React.SetStateAction<boolean>>
}

const FavoriteCardButtons: FC<Props> = ({ mode, handleSave, setMode, setToggleChildrenIsOn }: Props) => (
  <div className="standardcard-icons">
    {mode === 'adding' && (
      <Button name="saveJournalCard" theme="tiny" onClick={handleSave}>
        SPEICHERN
      </Button>
    )}
    {mode === 'normal' && (
      <Button
        name="addJournalCard"
        theme="secondary-tiny"
        onClick={() => {
          setMode('adding')
          setToggleChildrenIsOn(true)
        }}
      >
        HINZUFÜGEN
      </Button>
    )}
  </div>
)

export default FavoriteCardButtons
